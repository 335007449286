<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        :more-info-in-dialog="true"
      >
        <template slot="extraSubtitle">
          <extra-subtitle
            :abierta="formattedItem.abierta"
          />
        </template>
      </b10-view-summary>
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
      <b10-fab-button
        v-if="hasEditPerm($route.meta.permission.idobjeto)"
        :icon="$vuetify.icons.values.edit"
        @click="clickEdit"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './OperacioncrmViewData'
import ExtraSubtitle from '../components/ExtraSubtitle'
import { getLatLongURL } from '@/utils/maps'
import { get } from 'vuex-pathify'
import { TABLA } from '@/utils/consts'

export default {
  components: {
    ExtraSubtitle
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    editRoute: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      moreInfoRows: [],
      showingDialogs: {
        moreInfo: false,
      },
      toolbarOptions: {
        delete: {
          title: 'Eliminar',
          visible: true,
          icon: 'delete'
        },
        map: {
          title: 'Ver en el mapa',
          visible: true,
          icon: 'map',
        },
      },
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
    usuarioIdempleado: get('usuario/idempleado'),
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idoperacioncrm
        item.title = `${item.descripcion}`
        item.subtitle = this.$online.operacioncrm.subtitle(item, this.usuarioIdempleado)
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.toolbarOptions.delete.visible = this.hasDeletePerm(this.$route.meta.permission.idobjeto)
      this.title = `Operación ${this.item.dataset.serie_numero}`
      // asignar moreInfoRows en loadPage() para tener accesibles los permisos del store (no disponibles en data())
      this.moreInfoRows = {
        asignacion: {
          title: 'Asignación',
          rows: [
            { name: 'asignacion', label: 'Fecha de asignación', filter: this.$options.filters.shortDateTime},
            { name: 'empleado_responsable', label: 'Responsable'},
            { name: 'empleado_informador', label: 'Informador'},
            { name: 'delegacion_desc', label: 'Delegación'},
            { name: 'departamento_desc', label: 'Departamento'},
          ]
        },
        planificacion: {
          title: 'Planificación',
          rows: [
            { name: 'ffin', label: 'Fecha de finalización', filter: this.$options.filters.shortDateTime},
            { name: 'finicio', label: 'Fecha de inicio', filter: this.$options.filters.shortDateTime},
            { name: 'flimite', label: 'Fecha de límite', filter: this.$options.filters.shortDateTime},
            { name: 'fprevistafin', label: 'Fecha prevista de fin', filter: this.$options.filters.shortDateTime},
            { name: 'fproximarevision', label: 'Fecha de próxima revisión', filter: this.$options.filters.shortDateTime},
          ]
        },
        otros: {
          title: 'Otros datos',
          rows: [
            { name: 'toperacioncrm_desc', label: 'Tipo de operación'},
            { name: 'est_operacioncrm_dec', label: 'Estado' },
            { name: 'campania_desc', label: 'Campaña'},
            // { name: 'idprioridad', label: 'idprioridad'},
            // { name: 'idsubtoperacioncrm', label: 'idsubtoperacioncrm'},
            { name: 'serie_numero', label: 'Serie y número'},
            { name: 'observaciones', label: 'Observaciones'},
            { name: 'porcentaje_exito', label: 'Porcentaje de éxito'},
            { name: 'resultado', label: 'Resultado'},
          ]
        }
      }
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectOperacionCRM(
          this, this.routeParams.idoperacioncrm
        )
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      const resp = await Data.selectDetails(
        this,
        this.routeParams.idoperacioncrm,
        !!this.usuarioIdtecnico,
        !!this.usuarioIdcomercial,
        !!this.usuarioIdvigilante,
        this.usuarioIdempleado
      )
      // Relacionado con...
      if (resp.data.selectLoperacioncrm.result.dataset.length > 0) {
        const detailLoperacioncrm = this.addDetail(
          'loperacioncrm', 'Relacionado con...', 'Cliente, sistema, presupuesto, etc.', 'loperacioncrm'
        )
        detailLoperacioncrm.badge = resp.data.selectLoperacioncrm.result.dataset.length || 0
      }
      // ficheros
      if (this.hasViewPerm(this.permissions.operacionesAdjunto.id)) {
        const detailFicheros = this.addDetail(
          'adjuntos', 'Adjuntos', 'Fotografías y ficheros adjuntos', 'attach'
        )
        detailFicheros.badge = resp.data.selectFicheroCount.result.dataset[0].count || 0
      }
      // citas
      if (this.hasViewPerm(this.permissions.citas.id)) {
        const detailCitas = this.addDetail(
          'citas', 'Citas', 'Citas asociadas a la operación', 'citas'
        )
        detailCitas.badge = resp.data.selectCitaCount.result.dataset[0].count || 0
      }
      // contactos
      if (this.hasViewPerm(this.permissions.operacionesContactos.id)) {
        const detailContactos = this.addDetail(
          'contactos', 'Contactos', 'Contactos asociados a la operación', 'contactos'
        )
        detailContactos.badge = resp.data.selectContactosCount.result.dataset[0].count || 0
      }
      // Si es una suboperacion
      if (this.item.dataset.idoperacioncrmppal) {
        const suboperacion = this.addDetail(
          'operacionCRM', 'Operación principal', 'Es una subopearción de', 'operacionCRM'
        )
        suboperacion.badge = this.item.dataset.nroopcrmppal
      }
    },
    clickDetail (data) {
      let detailRoute = {
        name: null
      }
      switch (data.detail.name) {
      case 'adjuntos':
        detailRoute.name = 'ficheros__operacionescrm-fichero-list'
        detailRoute.params = {
          id: this.routeParams.idoperacioncrm,
          idtabla: TABLA.operacioncrm.idtabla,
        }
        break;
      case 'citas':
        detailRoute.name = 'citas__operacioncrm-cita-list'
        detailRoute.params = {
          idoperacioncrm: this.routeParams.idoperacioncrm
        }
        break;
      case 'contactos':
        detailRoute.name = 'contactos__contacto-cliente-list'
        detailRoute.params = {
          idoperacioncrm: this.routeParams.idoperacioncrm
        }
        break;
      case 'operacionCRM':
        detailRoute.name = 'operacionescrm__operacioncrm-view'
        detailRoute.params = {
          idoperacioncrm: this.item.dataset.idoperacioncrmppal
        }
        break;
      case 'loperacioncrm':
        detailRoute.name = 'loperacionescrm-loperaciocrm-list'
        detailRoute.params = {
          idoperacioncrm: this.routeParams.idoperacioncrm
        }
        break;
      default:
        console.error(`La opción ${data.detail.name} no está contemplada`);
      }
      if (detailRoute.name !== null) {
        this.$appRouter.push(detailRoute)
      }
    },
    async clickToolbarOption (option) {
      switch (option) {
      case this.toolbarOptions.map:
        this.verEnMapa()
        break;
      case this.toolbarOptions.delete:
        this.delete()
        break;
      default:
        console.error(`La opción ${option.detail.name} no está contemplada`);
      }
    },
    async clickEdit () {
      this.$appRouter.push({
        name: this.editRoute,
        params: {
          idoperacioncrm: this.routeParams.idoperacioncrm,
        },
      })
    },
    async verEnMapa () {
      const latitudLongitud = await Data.selectGeolocalizacionOperacionCRM(this, this.routeParams.idoperacioncrm)
      if (!!latitudLongitud){
        const [latitud, longitud] = latitudLongitud.split(';')
        window.open(getLatLongURL(latitud, longitud), '_blank')
      } else {
        this.$alert.showSnackbarError('No se encuentra ninguna geolocalización para la operación')
      }
    },
    async delete () {
      const res = await this.$alert.showConfirm(`¿Deseas eliminar la operación "${this.item.dataset.serie_numero}"`)
      if (res) {
        await this.$online.operacioncrm.delete(this.item.dataset.idoperacioncrm)
        await this.$dirty.deleted(this.$dirty.ENTITIES.remote.operacionCRM, this.item.dataset.idoperacioncrm)
        this.$alert.showSnackbarSuccess(`Se ha eliminado la operación "${this.item.dataset.serie_numero}"`)
        this.$appRouter.go(-1)
      }
    },
  },
}
</script>

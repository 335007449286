import { APIFilter } from '@/utils/api'
import { CITA, TABLA } from '@/utils/consts'

export default {
  async selectDetails (Vue, idoperacioncrm, esTecnico, esComercial, esVigilante, usuarioIdempleado) {
    let apiCalls = []
    const apiFilterCita = new APIFilter()
      .addExact('identificador', idoperacioncrm)
      .addExact('tipo', CITA.tipo.crm)
      .addExact('idempleado', usuarioIdempleado)
    apiCalls.push({ name: 'selectCitaCount', method: 'cita.select', params: { filter: apiFilterCita, wrapper: 'count' } })
    apiCalls.push(Vue.$online.fichero.ApiCallSelectCount(idoperacioncrm, TABLA.operacioncrm.idtabla, esTecnico, esComercial, esVigilante))
    const apiFilterGeneral = new APIFilter()
      .addExact('idoperacioncrm', idoperacioncrm)
      .addGT('estado', 0)
    apiCalls.push({ name: 'selectContactosCount', method: 'contactoCliente.select', params: { filter: apiFilterGeneral, wrapper: 'count' } })
    apiCalls.push({ name: 'selectLoperacioncrm', method: 'loperacioncrm.select', params: { filter: apiFilterGeneral } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectOperacionCRM (Vue, idoperacioncrm) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idoperacioncrm', idoperacioncrm)
    const resp = await Vue.$api.call('operacioncrm.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectGeolocalizacionOperacionCRM (Vue, idoperacioncrm) {
    const resp = await Vue.$api.call('operacioncrm.geolocalizacion', { idoperacioncrm: idoperacioncrm })
    return resp.data.result
  },
}
